import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { COLORS } from '../assets/colors';

import * as Api from '../API/API.js';

import CountUp from 'react-countup';

import * as Config from '../assets/config'

function PredictionCounter(props) {

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    //get API
    if(props.isVisible) {
      Api.getPredictionsNumber().then(val => {
        console.log(val.number);
        setCounter(val.number);

      }).catch(error =>{
          console.log("getPredictionNumber - ERROR");
          alert("Server Error");
      });
    }   
    
  }, [])


  return (
    <Container className='containercounter align-items-center justify-content-center'>
    {!props.isWarningModalVisible && props.isVisible && <Row className='counterrow gx-0'>
      <Col className='countercol align-self-center text-center '>
        <CountUp  style={styles.countervalue} end={counter} duration={0.5} />
        <div style={styles.countertext} >{Config.prediction_counter_labels.predictions_number_indication[props.language]}</div>
      </Col>
    </Row>}
    </Container>

  );
}

const styles = {

  countervalue: {
     color: COLORS.green_sophia,
     fontSize: 40,
     fontWeight: "bold"
  },

  countertext: {
    fontSize: 25,
  }
}

export default PredictionCounter;
