import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { COLORS } from '../assets/colors';
import Form from 'react-bootstrap/Form';

import * as Config from '../assets/config'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Tooltip from 'react-bootstrap/Tooltip';



function SaveDataSettings(props) {

  return (
    <Row className='savedatasettingsrow justify-content-center gx-0 mt-sm-5'>
        <Col xs={11} style={styles.savedatacol} className='savedatasettingscol pt-2 pb-2'>

          <Row className='text-center justify-content-center'>
            <Col  xs={11}>
            <Table   responsive>
              <thead>
                <tr>
                <th></th>
              {Config.preop_data_list.map((item, index) => {return(
                <th style={styles.savedatath} key={index} className='text-center align-middle ps-2 pe-2'>
                  {
                    (item.type === "number" || item.type === "range") && (item.label[props.language] + " (" + item.unit[props.language][props.system] +")" )
                  }
                  {
                    item.type === "toggle" && item.label_true[props.language]
                  }
                  {
                   item.type === "list" && item.label[props.language]
                  }


                </th>
              )})}
              {Config.surgery_data_list.map((item, index) => { return(
                <th style={styles.savedatath} key={index} className='text-center align-middle ps-2 pe-2'>
                 {
                   item.type === "list" && item.label[props.language]
                  }
                </th>
              )})}

              </tr>
              </thead>
              <tbody>

                {props.currentPredictionUserSettings[Config.preop_data_list[0].id] && <tr style={styles.savedatatr}>
                  <td style={styles.savedatatd} className='tablefirstcol text-center align-middle'>{Config.save_data_settings_labels.current_prediction_title[props.language]}</td>
                  {Config.preop_data_list.map((item, index) => {return(
                    <td style={styles.savedatatd} key={index} className='text-center align-middle ps-2 pe-2'>

                      {
                        item.type === "toggle" && (props.currentPredictionUserSettings[item.id] ? (Config.save_data_settings_labels.yes[props.language]) : (Config.save_data_settings_labels.no[props.language]))
                      }

                      {
                        (item.type === "number" || item.type === "range") && ( props.currentPredictionUserSettings[item.id] )
                      }
                      {
                        item.type === "list" && item.values[props.language][props.currentPredictionUserSettings[item.id]]
                      }


                    </td>
                  )})}
                  {Config.surgery_data_list.map((item, index) => { return(

                    <td style={styles.savedatatd} key={index} className='text-center align-middle ps-2 pe-2'>
                      {
                       item.type === "list" && item.values[props.language][props.currentPredictionUserSettings[item.id]]
                      }
                    </td>
                  )})}

                  <td style={styles.savedatatd} className='text-center align-middle ps-2 pe-2'>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip >
                            {Config.save_data_settings_labels.store_prediction_tooltip[props.language]}
                            </Tooltip>
                        }
                        >
                    <Button className="savedatabutton" onClick={() => props.saveCurrentData()}>

                      <i className="bi bi-save2"></i>

                    </Button>
                    </OverlayTrigger>
                  </td>
                </tr>}

                {props.savedUserSettings[Config.preop_data_list[0].id] && <tr style={styles.savedatatrarch} >
                <td style={styles.savedatatdarch} className='tablefirstcol text-center align-middle'>{Config.save_data_settings_labels.stored_prediction_title[props.language]}</td>
              {Config.preop_data_list.map((item, index) => {return(
                <td style={styles.savedatatdarch} key={index} className='text-center align-middle ps-2 pe-2'>

                  {
                    item.type === "toggle" && (props.savedUserSettings[item.id] ? (Config.save_data_settings_labels.yes[props.language]) : (Config.save_data_settings_labels.no[props.language]))
                  }

                   {
                    (item.type === "number" || item.type === "range") && ( props.savedUserSettings[item.id] )
                  }
                   {
                    item.type === "list" && item.values[props.language][props.savedUserSettings[item.id]]
                  }


                </td>
              )})}
              {Config.surgery_data_list.map((item, index) => { return(
                    <td style={styles.savedatatdarch} key={index} className='text-center align-middle ps-2 pe-2'>
                      {
                        item.type === "list" && item.values[props.language][props.savedUserSettings[item.id]]
                      }
                    </td>
                  )})}
              <td style={styles.savedatatdarch} className='text-center align-middle ps-2 pe-2'>
              <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip >
                    {Config.save_data_settings_labels.display_stored_prediction[props.language]}
                    </Tooltip>
                }
                >

                  <Form.Check
                      type={"switch"}
                      id="custom-switch2"
                      onChange={props.displaySavedResults}
                      name={"displaySavedResult"}
                      value={props.isSavedResultVisible}
                      checked={props.isSavedResultVisible}
                  />
              </OverlayTrigger>

              </td>
              </tr>}
              </tbody>
              </Table>
              </Col>

            </Row>
        </Col>
    </Row>
  );
}

const styles = {

savedatacol: {

},
savedatatr : {
  backgroundColor : COLORS.table_green_sophia,
  color : COLORS.green_sophia
},
savedatatrarch : {
  backgroundColor : COLORS.table_blue,
  color : COLORS.dark_blue
},

savedatatd : {
  color : COLORS.green_sophia,
  borderColor : COLORS.light_green_sophia,
  minWidth: 50,
  verticalAlign: "middle",
  textAlign:"center"


},

savedatatdarch : {
  color : COLORS.dark_blue,
  minWidth: 50,
  verticalAlign: "middle",
  textAlign:"center"


},
savedatath: {
  color : COLORS.green_sophia,
  minWidth: 50,
  verticalAlign: "middle",
  textAlign:"center"

},
}

export default SaveDataSettings;
