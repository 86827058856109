import React, {useState} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import * as Config from '../assets/config'

import { COLORS } from '../assets/colors';


function UniquePatientSettings(props) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();


        props.clearData();

        if (form.checkValidity() === true) {

            let data = Object.assign(
              {"system": props.system}, ...Config.preop_data_list.map((x) => ({[x.id]: props.userSettings[x.id]})), ...Config.surgery_data_list.map((x) => ({[x.id]: props.userSettings[x.id]}))
            )
            props.handlePredict(data);
        }


        setValidated(true);
        };


    const handleChange = e => {
        const { name, value, type } = e.target;
            if(type === "checkbox") {
                props.setUserSettings(prevState => ({
                    ...prevState,
                    [name]: !props.userSettings[name]
                }));
            }
            else if(name == "height") {
              props.setUserSettings(prevState => ({
                    ...prevState,
                    [name]: parseFloat(value)
                }));
            }
            else {
                props.setUserSettings(prevState => ({
                    ...prevState,
                    [name]: parseInt(value)
                }));
            }
            if(name === "type2diabetes" && parseInt(value) !== 2 ) {
                props.setUserSettings(prevState => ({
                    ...prevState,
                    "yearsWithDiabetes": 0
                }));
            }
    };

    return (
        <Container className='containersettings'>
            <Row style={styles.sectiontitle} className='preopdatatitle mt-3'>
                <Col>
                {Config.patient_settings_labels.preoperative_data_category_title[props.language]}
                </Col>
            </Row>
            <Row className="preopdatarow  p-3 justify-content-center">
                <Form noValidate validated={validated} id="mainform" onSubmit={handleSubmit}>


                    {Config.preop_data_list.map((item, index) => { return(

                    <Row className="mb-3" key={index}>
                        {
                                item.type === "range" &&
                                <Form.Group as={Col} controlId={"validationCustom"+index}>
                                    <Row className='text-center align-items-center' >
                                        <Form.Label style={styles.settingsLabel}>{item.label[props.language]}</Form.Label>
                                    </Row>
                                    <Row className='mt-1 align-items-center justify-content-center' >
                                        <Col xs="12" sm="12" md="12" lg="7" xl="8" xxl="8">
                                            <Form.Range
                                            required
                                            onChange={handleChange}
                                            name={item.id}
                                            value={props.userSettings[item.id]? props.userSettings[item.id] : item.min}
                                            min={item.min[props.system]}
                                            max={item.max[props.system]}
                                            step={item.step[props.system]}
                                            variant='success'
                                            />
                                        </Col>
                                        <Col xs="11" sm="10" md="7"lg="5" xl="4" xxl="4" className='formcol'>
                                            <Row className='align-items-center'>
                                                <Col>
                                                    <Form.Control
                                                    className='withoutarrow numberinput'
                                                    required
                                                    style={styles.form}
                                                    value={!isNaN(props.userSettings[item.id]) ? props.userSettings[item.id] : 'NaN'}
                                                    type="number"
                                                    onChange={handleChange}
                                                    name={item.id}
                                                    min={item.min[props.system]}
                                                    max={item.max[props.system]}
                                                    step={item.step[props.system]}
                                                    />
                                                    <Row className='text-center align-items-center unit'>
                                                        <Form.Label className='text-center' style={styles.settingsLabelUnit}>{item.unit[props.language][props.system]}</Form.Label>
                                                    </Row>
                                                    <Form.Control.Feedback style={styles.feedback} className='text-center' type="invalid">
                                                        Min-Max: {item.min[props.system]}-{item.max[props.system]} {item.unit[props.language][props.system]}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </Form.Group>
                            }
                            {
                                item.type === "toggle" &&
                                <Form.Group as={Col} controlId={"validationCustom"+index}>
                                    <Row className='mt-4 mb-4 align-items-center justify-content-center' >
                                        <Col className='text-center align-items-center  justify-content-center' xs="12" lg="4">
                                            <Form.Label style={styles.settingsLabel}>{item.label_false[props.language]}</Form.Label>
                                        </Col>

                                        <Col className='text-center align-items-center justify-content-center'  xs="12" lg="4">

                                            <Form.Check
                                                type={"switch"}
                                                id="custom-switch"
                                                onChange={handleChange}
                                                name={item.id}
                                                value={props.userSettings[item.id]}
                                                checked={props.userSettings[item.id]}
                                            />
                                        </Col>

                                        <Col className='text-center align-items-center  justify-content-center'   xs="12" lg="4">

                                            <Form.Label style={styles.settingsLabel}>{item.label_true[props.language]}</Form.Label>

                                        </Col>
                                    </Row>
                                </Form.Group>
                            }
                            {
                                item.type === "list" &&
                                <Form.Group as={Col} controlId={"validationCustom"+index}>
                                    <Row className='text-center align-items-center' >
                                        <Form.Label style={styles.settingsLabel}>{item.label[props.language]}</Form.Label>
                                    </Row>
                                    <Row className='mt-1 align-items-center justify-content-center' >
                                        <Col xs="12" md="8" lg="12" className='selectcol'>
                                            <Form.Select
                                            onChange={handleChange}
                                            className='selectinput'
                                            name={item.id}
                                            value={props.userSettings[item.id]}
                                            >
                                                {item.values[props.language].map((item, index) => {return( <option key={index} value={index}>{item}</option>)})}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            }
                            {
                                (item.type === "number" && (props.userSettings[item.depends_on] === 2)) &&
                                <Form.Group as={Col} controlId={"validationCustom"+index}>
                                    <Row className='text-center align-items-center' >
                                        <Form.Label style={styles.settingsLabel}>{item.label[props.language]}</Form.Label>
                                    </Row>
                                    <Row className='mt-1 align-items-center justify-content-center' >
                                        <Col xs="12" sm="10" md="7"lg="7" xl="4" xxl="4" className='formcol'>
                                            <Form.Control
                                            required
                                            className='numberinput'
                                            style={styles.form}
                                            type="number"
                                            min={item.min[props.system]}
                                            max={item.max[props.system]}
                                            onChange={handleChange}
                                            name={item.id}
                                            value={!isNaN(props.userSettings[item.id]) ? props.userSettings[item.id] : 'NaN'}/>
                                            <Row className='text-center align-items-center unit'>
                                                <Form.Label className='text-center' style={styles.settingsLabelUnit}>{item.unit[props.language][props.system]}</Form.Label>
                                            </Row>
                                            <Form.Control.Feedback style={styles.feedback} className='text-center' type="invalid">
                                                Min-Max: {item.min[props.system]}-{item.max[props.system]} {item.unit[props.language][props.system]}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            }

                        </Row>
                    )})}
                </Form>
            </Row>
            <Row style={styles.sectiontitle} className='mt-3'>
                <Col>
                    {Config.patient_settings_labels.surgery_data_category_title[props.language]}
                </Col>
            </Row>

            <Row className="preopdatarow p-3 justify-content-center">
                <Form noValidate validated={validated} id="surgerydateform">
                    {Config.surgery_data_list.map((item, index) => { return(
                        <Row className="mb-3" key={index}>
                            {
                                    item.type === "list" &&
                                    <Form.Group as={Col} controlId={"validationCustom"+index}>
                                        <Row className='text-center align-items-center' >
                                            <Form.Label style={styles.settingsLabel}>{item.label[props.language]}</Form.Label>
                                        </Row>
                                        <Row className='mt-1 align-items-center justify-content-center' >
                                            <Col xs="12" md="8" lg="12" className='selectcol'>
                                                <Form.Select
                                                onChange={handleChange}
                                                className='selectinput'
                                                name={item.id}
                                                value={props.userSettings[item.id]}
                                                >
                                                    {item.values[props.language].map((item, index) => {return( <option key={index} value={index}>{item}</option>)})}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                }
                        </Row>
                    )})}
                </Form>
            </Row>


            <Row className="justify-content-center text-center buttonrow">
            <Col  xs="8">
                <Button style={styles.predictbutton} className="predictbutton" type="submit" form="mainform">{Config.patient_settings_labels.predict_button[props.language]}</Button>
            </Col>
            </Row>

        </Container>
    );
}

const styles = {
    settingsLabel:{
        color: COLORS.green_sophia,
        fontWeight:"bold",
        fontSize: 18,
        margin: 0
    },

    settingsLabelUnit:{
        color: COLORS.green_sophia,
        fontWeight:"bold",
        fontSize: 15
    },

    feedback:{
        fontSize: 12

    },

    yesno:{
        color: COLORS.white,
        fontSize: 18
    },

    form : {
        textAlign: "center",
    },

    sectiontitle: {
        color: COLORS.light_gray,
        fontWeight:"bold",
        fontSize: 18,
        marginLeft: 0
    }


  }

export default UniquePatientSettings;
