import React, {useState} from 'react';

import Home from './Components/Home';
import About from './Components/About';
import Partners from './Components/Partners';

import WarningModal from './Components/WarningModal';
import ContactModal from './Components/ContactModal';

import MenuBar from './Components/MenuBar';

import * as Config from './assets/config'

import {SettingContext, ResultContext, VisuContext, SavedResultContext, SavedSettingContext, CurrentPredictionSettingContext} from "./Context";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";



function App() {

  const [isWarningModalVisible, setWarningModalVisible] = useState(true);
  const [isContactModalVisible, setContactModalVisible] = useState(false);

  const [language, setLanguage] = useState("EN");
  const [system, setSystem] = useState("MET");
  const [selectedTab, setSelectedTab] = useState("/");


  const [userSettings, setUserSettings] = useState(
    Object.assign({}, ...Config.preop_data_list.map((x) => ({[x.id]: x.default[system]})), ...Config.surgery_data_list.map((x) => ({[x.id]: x.default[system]})))
  );

  const [currentPredictionUserSettings, setCurrentPredictionUserSettings] = useState(
    Object.assign({}, ...Config.preop_data_list.map((x) => ({[x.id]: null})), ...Config.surgery_data_list.map((x) => ({[x.id]: null})))
  );

  const [savedUserSettings, setSavedUserSettings] = useState(
    Object.assign({}, ...Config.preop_data_list.map((x) => ({[x.id]: null})), ...Config.surgery_data_list.map((x) => ({[x.id]: null})))
  );
  const [results, setResults] = useState({"table_values" : null, "smooth_coef": null});

  const [savedResults, setSavedResults] = useState({"table_values" : null, "smooth_coef": null});

  const [visuSettings, setVisuSettings] = useState({"interventionType" : 0, "savedInterventionType" : 0, "isTableOpen": false, "isSavedResultVisible" : true, "indicatorToDisplay" : "weight"});


  const handleCloseWarningModal = () => {
    setWarningModalVisible(false);
}

  const handleCloseContactModal = () => {
    setContactModalVisible(false);
  }

  const handleOpenContactModal = () => {
    setContactModalVisible(true);
  }

  return (
    <Router>
       <WarningModal
        language={language}
        handleClose={handleCloseWarningModal}
        show={isWarningModalVisible}
        setLanguage={setLanguage}/>
      <ContactModal
        language={language}
        handleClose={handleCloseContactModal}
        show={isContactModalVisible}/>

      <div>
      <MenuBar
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            handleOpenContactModal={handleOpenContactModal}
            system={system}
            setSystem={setSystem}
            language={language}
            setLanguage={setLanguage}/>
          <CurrentPredictionSettingContext.Provider value={{currentPredictionUserSettings, setCurrentPredictionUserSettings}}>
            <SavedSettingContext.Provider value={{savedUserSettings, setSavedUserSettings}}>
              <SavedResultContext.Provider value={{savedResults, setSavedResults}}>
                <VisuContext.Provider value={{visuSettings, setVisuSettings}}>
                  <ResultContext.Provider value={{results, setResults}}>
                    <SettingContext.Provider value={{userSettings, setUserSettings}}>
                      <Routes>
                        <Route path="/" element={<Home isWarningModalVisible={isWarningModalVisible} system={system} setSystem={setSystem} language={language} setLanguage={setLanguage} setSelectedTab = {setSelectedTab} />} />
                        <Route path="/about" element={<About language={language} setLanguage={setLanguage} setSelectedTab = {setSelectedTab} />} />
                        <Route path="/partners" element={<Partners language={language} setLanguage={setLanguage} setSelectedTab = {setSelectedTab} />} />
                      </Routes>
                    </SettingContext.Provider>
                  </ResultContext.Provider>
                </VisuContext.Provider>
              </SavedResultContext.Provider>
            </SavedSettingContext.Provider>
          </CurrentPredictionSettingContext.Provider>
      </div>
    </Router>
  );
}


export default App;
