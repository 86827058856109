export const COLORS = {
    blue: "#1d91d1",
    dark_green_sophia: "#00331a",
    green_sophia: "#006633",
    light_green_sophia: "#93c01f",
    area_green_sophia: "#d3ed8f",  //screen1 : #93c01f  / screen2 : #d3ed8f / screen3 : #d8d8d8 / screen 4 : #abdaf3
    table_green_sophia: "#e1efb8",
    table_blue: "#c8e4f4",
    ultra_light_green_sophia: "#d9f0a0",
    light_gray:"#F5F5F5",
    white:"#FFFFFF",
    dark_blue:"#0E4868",
    light_blue:"#abdaf3",
    gray:"#808080",
    black:"#000000"
}