import React from 'react';

import NavBar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import franceflag from '../Images/ic_france.png'
import ukflag from '../Images/ic_uk.png'
import Form from 'react-bootstrap/Form';
import { Container } from 'react-bootstrap';
import { COLORS } from '../assets/colors';
import Button from 'react-bootstrap/Button';
import * as Config from '../assets/config'

import {
  Link
} from "react-router-dom";



function MenuBar(props) {




  return (
    <NavBar bg="light" style={styles.menubar} className="menubar" fixed="top" expand="lg">
      <Container fluid className='menubarcontainer'>
        <Col xs={8} sm={8}  lg={4}>
        <NavBar.Brand style={styles.brand} className="" href="/"> <img
                      alt=""
                      src={require("../Images/app_logo_sophia.png")}
                      id={"logosophia"}
                      className="sophialogo"
                    />
            <a  style={{ cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(23)00135-8/fulltext">
            <img
                        alt=""
                        src={require("../Images/lancet_digital_health_small.png")}
                        id={"logoldh"}
                        className="ldhlogo"
                      />
            </a>
          </NavBar.Brand>
      </Col>
      <Col  xs={4} sm={4} lg={6} className="text-end justify-content-end">
      <NavBar.Toggle className="me-3" aria-controls="basic-navbar-nav" />
      <NavBar.Collapse id="basic-navbar-nav">
        <Nav
          className="navcenter ">

          <Link style={props.selectedTab === "/" ? styles.activeLink : styles.inactiveLink} className="me-3" to="/" onClick={()=>{props.setSelectedTab("/")}}>{Config.menu_bar_labels.prediction_tab[props.language]}</Link>
          <Link  style={props.selectedTab === "/about" ? styles.activeLink : styles.inactiveLink} className="me-3" to="/about" onClick={()=>{props.setSelectedTab("/about")}}>{Config.menu_bar_labels.about_tab[props.language]}</Link>
          <Link  style={props.selectedTab === "/partners" ? styles.activeLink : styles.inactiveLink} className="me-3" to="/partners" onClick={()=>{props.setSelectedTab("/partners")}}>{Config.menu_bar_labels.partners_tab[props.language]}</Link>
          <Col lg={1} xs={12} className="text-end align-self-end">
          <Button
            className='mailtobutton'
            to='#'
            onClick={() => {
               props.handleOpenContactModal();
            }}
        >
            {Config.menu_bar_labels.contact_button[props.language]}
        </Button>
        </Col>
        </Nav>

        <Form  >
            <div key={`inline-radio`} >
              <Form.Check
                inline
                label={"Metric"}
                name="systemgroup1"
                type={"radio"}
                value="MET"
                checked={props.system === "MET"}
                id={`systemlabel-1`}
                onChange={e => {console.log(e.target.value); props.setSystem(e.target.value)}}
              />
              <Form.Check
                inline
                label={"Imper."}
                name="systemgroup1"
                type={"radio"}
                value="IMP"
                checked={props.system === "IMP"}
                id={`systemlabel-2`}
                onChange={e => {console.log(e.target.value); props.setSystem(e.target.value)}}
              />

            </div>
        </Form>

        <Form  >
            <div key={`inline-radio`} >
              <Form.Check
                inline
                label={<img
                  src={franceflag}
                  alt=""
                  height={"20px"}  ></img>}
                name="group1"
                type={"radio"}
                value="FR"
                checked={props.language === "FR"}
                id={`languagelabel-1`}
                onChange={e => {console.log(e.target.value); props.setLanguage(e.target.value)}}
              />
              <Form.Check
                inline
                label={<img
                  src={ukflag}
                  alt=""
                  height={"20px"} ></img>}
                name="group1"
                type={"radio"}
                value="EN"
                checked={props.language === "EN"}
                id={`languagelabel-2`}
                onChange={e => {console.log(e.target.value); props.setLanguage(e.target.value)}}
              />

            </div>
          </Form>
      </NavBar.Collapse>
      </Col>
      </Container>
    </NavBar>
  );
}

const styles = {
  menubar:{
    fontSize: 15
  },

  brand:{
    fontWeight: "bold",
    fontSize: 18,
    color: COLORS.green_sophia

  },

  activeLink: {
    color: COLORS.black,
    textDecoration: 'none'
  },

  inactiveLink: {
    color: COLORS.gray,
    textDecoration: 'none'
  }

}

export default MenuBar;
