import React, {useEffect} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { COLORS } from '../assets/colors';

import * as Config from '../assets/config'

function About(props) {

  useEffect(() => {
    props.setSelectedTab("/about");
})

  

  
  
  return (
    <div className='content'>
        <Row className='contentrow gx-0'>
            <Col style={styles.leftcol} xs={0} sm={4} className="justify-content-center leftcol horizontal-gradient">
            </Col>
            <Col className='rightcol' style={styles.ritghtcol}  xs={12} sm={8}>
              <Row  className='gx-0'>
                <Col className='pt-3 pt-sm-5 ps-3 ps-sm-0 pe-3 pe-sm-5'>
                  {Config.aboutText[props.language]}
                </Col>
              </Row>
            </Col>
        </Row>
    </div>
  );
}

const styles = {

  leftcol: {
      backgroundColor : COLORS.light_green_sophia,
  },

  ritghtcol: {
      backgroundColor : COLORS.light_gray
  },
}

export default About;
