import React, {useState, useEffect, useContext, useRef} from 'react';
import UniquePatientSettings from './UniquePatientSettings'
import UniquePatientChart from './UniquePatientChart'
import UniquePatientTable from './UniquePatientTable'
import SaveDataSettings from './SaveDataSettings'
import PredictionCounter from './PredictionCounter'


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Collapse from 'react-bootstrap/Collapse';

import * as Config from '../assets/config'

import * as Api from '../API/API.js';

import { COLORS } from '../assets/colors';
import { ResultContext, SettingContext, VisuContext, SavedSettingContext, SavedResultContext, CurrentPredictionSettingContext} from '../Context';


function Home(props) {

    const myRef = useRef(null)

    const {userSettings, setUserSettings} = useContext(SettingContext);
    const {savedUserSettings, setSavedUserSettings} = useContext(SavedSettingContext);
    const {currentPredictionUserSettings, setCurrentPredictionUserSettings} = useContext(CurrentPredictionSettingContext);

    const {results, setResults} = useContext(ResultContext);
    const {savedResults, setSavedResults} = useContext(SavedResultContext);

    const {visuSettings, setVisuSettings} = useContext(VisuContext);


    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        props.setSelectedTab("/");
    })

    const clearData = () => {
        console.log("cleared data");
        setResults({"table_values" : null, "smooth_coef": null});
    }

    const handleDisplaySavedResults = () => {
        setVisuSettings(prevState => ({
            ...prevState,
            "isSavedResultVisible": !visuSettings.isSavedResultVisible
        }));
    }

    const handleTable = () => {
        let prevTable = visuSettings.isTableOpen;
        setVisuSettings(prevState => ({
            ...prevState,
            "isTableOpen": !visuSettings.isTableOpen
        }));
       !prevTable && setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 300);
    }

    const handleIndicatorToDisplay = (ind) => {
        setVisuSettings(prevState => ({
            ...prevState,
            "indicatorToDisplay": ind
        }));
    }

    const handlePredict = (data) => {
            setVisuSettings(prevState => ({
                ...prevState,
                "interventionType": data.interventionType
            }));
            setCurrentPredictionUserSettings(data);
            setLoading(true);
            executeScroll();

                Api.predict(data).then(val => {
                    console.log(val);
                    setLoading(false);
                    setResults(val);

                }).catch(error =>{
                    console.log("predict - ERROR");
                    setLoading(false);
                    alert("Server Error");
                });

    }

    const executeScroll = () => myRef.current.scrollIntoView()

    const handleSaveCurrentData = () => {
        setSavedUserSettings(currentPredictionUserSettings);
        setCurrentPredictionUserSettings(Object.assign({}, ...Config.preop_data_list.map((x) => ({[x.id]: null})), ...Config.surgery_data_list.map((x) => ({[x.id]: null}))));
        setSavedResults(results);
        setResults({"table_values" : null, "smooth_coef": null});
        console.log("saving results");
        console.log(results);
        setVisuSettings(prevState => ({
            ...prevState,
            "savedInterventionType": visuSettings.interventionType
        }));

    }


  return (
    <div className='content'>
        <Row className='contentrow gx-0'>
            <Col style={styles.leftcol} xs={12} sm={4} className="justify-content-center leftcol horizontal-gradient">
                <UniquePatientSettings
                clearData = {clearData}
                handlePredict = {handlePredict}
                userSettings = {userSettings}
                setUserSettings = {setUserSettings}
                system={props.system}
                language={props.language}/>
            </Col>
            <Col ref={myRef} className="rightcol" style={styles.rightcol} xs={12} sm={8}>
                {isLoading &&
                <Row className='spinnerrow justify-content-center align-items-center gx-0'>
                    <Spinner style={{color: COLORS.light_green_sophia}} className="spinner" animation="grow" />
                </Row>
                }
                {(currentPredictionUserSettings[Config.preop_data_list[0].id] || savedUserSettings[Config.preop_data_list[0].id]) &&<SaveDataSettings
                    system={props.system}
                    language={props.language}
                    saveCurrentData={handleSaveCurrentData}
                    savedUserSettings={savedUserSettings}
                    currentPredictionUserSettings={currentPredictionUserSettings}
                    displaySavedResults={handleDisplaySavedResults}
                    isSavedResultVisible={visuSettings.isSavedResultVisible}

                    />}
                <Row className='uniquepatientchartrow gx-0'>
                    {((results.smooth_coef && results.table_values) || (savedResults.smooth_coef && savedResults.table_values)) ? <UniquePatientChart
                    indicatorToDisplay = {visuSettings.indicatorToDisplay}
                    setIndicatorToDisplay={handleIndicatorToDisplay}
                    interventionType={visuSettings.interventionType}
                    savedInterventionType={visuSettings.savedInterventionType}
                    smoothCoef={results.smooth_coef}
                    savedChartData={savedResults.table_values}
                    savedSmoothCoef={savedResults.smooth_coef}
                    isSavedResultVisible={visuSettings.isSavedResultVisible}
                    system={props.system}
                    language={props.language}
                    /> :
                    <PredictionCounter
                    isVisible={Config.display_predictions_number}
                    isWarningModalVisible={props.isWarningModalVisible}
                    system={props.system}
                    language={props.language}/>}
                </Row>



                {(results.table_values || (savedResults.table_values && visuSettings.isSavedResultVisible)) && <Row className='mt-5 mt-sm-0 align-items-center uniquepatienttablerow'>
                    <Col>
                        <Row>
                            <Col className='ms-2 ms-sm-5' xs={10}>
                                <p className='openchartlink'  style={styles.openchartlink}  onClick={() => {handleTable();}}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={visuSettings.isTableOpen}>{visuSettings.isTableOpen ? <i className="bi bi-caret-down me-2"></i> :  <i className="bi bi-caret-right me-2"></i>}
                                    {Config.home_labels.table_of_values_title[props.language]}</p>

                            </Col>
                        </Row>
                        <Row className='mb-5'>
                            <Collapse in={visuSettings.isTableOpen}>
                                <div id="example-collapse-text">
                                <UniquePatientTable
                                tableData={results.table_values}
                                savedTableData={savedResults.table_values}
                                isSavedResultVisible={visuSettings.isSavedResultVisible}
                                system={props.system}
                                language={props.language}
                                />
                                </div>
                            </Collapse>
                        </Row>
                    </Col>
                </Row> }
            </Col>

        </Row>

    </div>
  );
}

const styles = {

    leftcol: {
        backgroundColor : COLORS.light_green_sophia,
    },

    rightcol: {
        backgroundColor : COLORS.light_gray
    },

    openchartlink: {
        fontSize: 25,
        color : COLORS.green_sophia
    }


  }

export default Home;
