import { createContext } from "react";

export const SettingContext  = createContext(null);
export const ResultContext  = createContext(null);
export const VisuContext = createContext(null);
export const SavedResultContext = createContext(null);
export const SavedSettingContext = createContext(null);
export const CurrentPredictionSettingContext = createContext(null);


//export const DataContext  = createContext(null);