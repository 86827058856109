import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import * as Config from '../assets/config';
import franceflag from '../Images/ic_france.png'
import ukflag from '../Images/ic_uk.png'
import Form from 'react-bootstrap/Form';


function WarningModal(props) {

  
  return (
    <Modal 
    show={props.show} 
    onHide={props.handleClose}
    size="lg"
    centered>
        <Modal.Header closeButton>
         
          
          <Modal.Title className='justify-content-center align-items-center'> <Row><Col xs={6}>{Config.warning_modal_labels.modal_title[props.language]}
          </Col>
          <Col className='text-end' xs={6}>
          <Form style={styles.languageform}  >
        
            <div key={`inline-radio`} >
              <Form.Check
                inline
                label={<img 
                  src={franceflag} 
                  alt=""
                  height={"20px"}  ></img>}
                name="group1"
                type={"radio"}
                value="FR"
                checked={props.language === "FR"}
                id={`languagelabel-1`}
                onChange={e => {console.log(e.target.value); props.setLanguage(e.target.value)}}
              />
              <Form.Check
                inline
                label={<img 
                  src={ukflag} 
                  alt=""
                  height={"20px"} ></img>}
                name="group1"
                type={"radio"}
                value="EN"
                checked={props.language === "EN"}
                id={`languagelabel-2`}
                onChange={e => {console.log(e.target.value); props.setLanguage(e.target.value)}}
              />
              
            </div>
          </Form> </Col></Row> </Modal.Title>
          
          
        </Modal.Header>
        <Modal.Body>{Config.warningText[props.language]}</Modal.Body>
      </Modal>
  );
}


const styles = {
  languageform:{
    fontSize: 15
  }
 
}


export default WarningModal;
