import { COLORS } from './colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointRight } from '@fortawesome/free-regular-svg-icons'

export const display_predictions_number = false;

export const preop_data_list = [
    {
        "id": "weight",
        "type": "range",
        "label": {
            "EN": "Weight",
            "FR": "Poids"
        },
        "unit": {
            "EN": {
              "MET": "kg",
              "IMP": "lb."
            },
            "FR": {
              "MET": "kg",
              "IMP": "lb"
            }
        },
        "min": {
          "MET": 25,
          "IMP": 55
        },
        "max": {
          "MET": 300,
          "IMP": 660,
        },
        "step": {
          "MET": 1,
          "IMP": 1,
        },
        "default": {
          "MET": 120,
          "IMP": 265,
        }
    },
    {
        "id": "height",
        "type": "range",
        "label": {
            "EN": "Height",
            "FR": "Taille"
        },
        "unit": {
            "EN": {
              "MET": "cm",
              "IMP": "ft"
            },
            "FR": {
              "MET": "cm",
              "IMP": "ft"
            }
        },
        "min": {
          "MET": 100,
          "IMP": 3.3,
        },
        "max": {
          "MET": 230,
          "IMP": 7.5,
        },
        "step": {
          "MET": 1,
          "IMP": 0.1,
        },
        "default": {
          "MET": 170,
          "IMP": 5.5,
        }
    },
    {
        "id": "age",
        "type": "range",
        "label": {
            "EN": "Age",
            "FR": "Âge"
        },
        "unit": {
            "EN": {
              "MET": "years",
              "IMP": "years"
            },
            "FR": {
              "MET": "ans",
              "IMP": "ans"
            }
        },
        "min": {
          "MET": 18,
          "IMP": 18
        },
        "max": {
          "MET": 70,
          "IMP": 70,
        },
        "step": {
          "MET": 1,
          "IMP": 1,
        },
        "default": {
          "MET": 30,
          "IMP": 30
        }
    },
    {
        "id": "isSmoker",
        "type": "toggle",
        "label_false": {
            "EN": "Non-smoker",
            "FR": "Non-fumeur"
        },
        "label_true": {
            "EN": "Smoker",
            "FR": "Fumeur"
        },
        "default": {
          "MET": false,
          "IMP": false
        }
    },
    {
        "id": "type2diabetes",
        "type": "list",
        "label": {
            "EN": "Type 2 diabetes",
            "FR": "Diabète de type 2"
        },
        "values": {
            "EN": ["No diabetes", "Prediabetes", "Diabetes"],
            "FR": ["Pas de diabète", "Pré-diabète", "Diabète"]
        },
        "default": {
          "MET": 0,
          "IMP": 0
        }
    },
    {
        "id": "yearsWithDiabetes",
        "type": "number",
        "depends_on": "type2diabetes",
        "label": {
            "EN": "Diabetes duration",
            "FR": "Durée du diabète"
        },
        "unit": {
            "EN": {
              "MET": "years",
              "IMP": "years"
            },
            "FR": {
              "MET": "ans",
              "IMP": "ans"
            }
        },
        "min": {
          "MET": 0,
          "IMP": 0,
        },
        "max": {
          "MET": 70,
          "IMP": 70
        },
        "default": {
          "MET": 0,
          "IMP": 0
        }
    },


]

export const surgery_data_list = [
    {
        "id": "interventionType",
        "type": "list",
        "label": {
            "EN": "Type of intervention",
            "FR": "Type d'intervention"
        },
        "values": {
            "EN": ["Gastric Bypass", "Gastric band", "Sleeve Gastrectomy"],
            "FR": ["Bypass gastrique  ", "Anneau gastrique", "Sleeve Gastrectomie"]
        },
        "default": {
          "MET": 0,
          "IMP": 0,
        }
    },
]

export const indicators_list = [
   {
        "id": "weight",
        "label": {
            "EN": "Weight",
            "FR": "Poids"
        },
        "label_with_units": {
            "EN": {
              "MET": "Weight (kg)",
              "IMP": "Weight (lb.)",
            },
            "FR": {
              "MET": "Poids (kg)",
              "IMP": "Poids (lb)",
            }
        },
        "description": {
            "EN": "",
            "FR": ""
        },
        "chart_description": {
            "EN": "The *green dashed line* represents the predicted weight trajectory. The *translucent green area* represents the typical deviation observed on 50% of the patients with similar characteristics.",
            "FR": "La *ligne verte en pointillés* représente la trajectoire de poids prédite. La *zone verte* représente la déviation observée chez 50% des patients ayant des caractéristiques similaires."
        },
        "chart_title": {
            "EN": "Prediction of weight trajectory after surgery",
            "FR": "Prédiction de la trajectoire du poids après opération"
        },
        "unit": "kg",
        "roundedTo":0,
        "error": "weight_error",
    },
    {
        "id": "twl",
        "label": {
            "EN": "TWL",
            "FR": "PP"
        },
        "label_with_units": {
            "EN": {
              "MET": "TWL (%)",
              "IMP": "TWL (%)"
            },
            "FR": {
              "MET": "PP (%)",
              "IMP": "PP (%)"
            }
        },
        "description": {
            "EN": "Total Weight Loss: percentage loss between post-op and pre-op weights after surgery. TWL = (Pre-op Weight - Post-op Weight) / Pre-op Weight * 100.",
            "FR": "Perte de Poids totale: pourcentage de perte entre le poids avant et après chirurgie. PP = (Poids avant chirurgie - Poids après chirurgie)/ Poids avant chirurgie * 100."
        },
        "chart_description": {
            "EN": "The *green dashed line* represents the predicted TWL trajectory. The *translucent green area* represents the typical deviation observed on 50% of the patients with similar characteristics.",
            "FR": "La *ligne verte en pointillés* représente la trajectoire de PP prédite. La *zone verte* représente la déviation observée chez 50% des patients ayant des caractéristiques similaires."
        },
        "chart_title": {
            "EN": "Prediction of TWL trajectory after surgery",
            "FR": "Prédiction de la trajectoire de PP après opération"
        },
        "unit": "%",
        "roundedTo":0,
        "error": "twl_error",
    },
    {
        "id": "bmi",
        "label": {
            "EN": "BMI",
            "FR": "IMC"
        },
        "label_with_units": {
            "EN": {
              "MET": "BMI",
              "IMP": "BMI",
            },
            "FR": {
              "MET": "IMC",
              "IMP": "IMC",
            }
        },
        "description": {
            "EN": "Body Mass Index (BMI): ratio of body mass (kg) divided by the square of the body height (m). Unit: kg/m2.",
            "FR": "Indice de Masse Corporelle (IMC): ratio du poids (kg) divisé par la taille (m) au carré. Unité: kg/m2."
        },
        "chart_description": {
            "EN": "The *green dashed line* represents the predicted BMI trajectory. The *translucent green area* represents the typical deviation observed on 50% of the patients with similar characteristics.",
            "FR": "La *ligne verte en pointillés* représente la trajectoire d'IMC prédite. La *zone verte* représente la déviation observée chez 50% des patients ayant des caractéristiques similaires."
        },
        "chart_title": {
            "EN": "Prediction of BMI trajectory after surgery",
            "FR": "Prédiction de la trajectoire de l'IMC après opération"
        },
        "unit": "",
        "roundedTo":1,
        "error": "bmi_error",
    },
    {
        "id": "ewl",
        "label": {
            "EN": "EWL",
            "FR": "PEP"
        },
        "label_with_units": {
            "EN": {
              "MET": "EWL (%)",
              "IMP": "EWL (%)"
            },
            "FR": {
              "MET": "PEP (%)",
              "IMP": "PEP (%)"
            }
        },
        "description": {
            "EN": "Excess Weight Loss: percentage loss of 'excess weight' after surgery. The 'excess weight' is the difference between pre-operative weight and the weight corresponding to BMI = 25.",
            "FR": "Perte d'Excès de Poids: pourcentage de perte du 'poids en excès' après chirurgie. Le 'poids en excès' correspond à la différence entre le poids avant chirurgie et le poids correspondant à IMC = 25."
        },
        "chart_description": {
            "EN": "The *green dashed line* represents the predicted EWL trajectory. The *translucent green area* represents the typical deviation observed on 50% of the patients with similar characteristics.",
            "FR": "La *ligne verte en pointillés* représente la trajectoire d'PEP prédite. La *zone verte* représente la déviation observée chez 50% des patients ayant des caractéristiques similaires."
        },
        "chart_title": {
            "EN": "Prediction of EWL trajectory after surgery",
            "FR": "Prédiction de la trajectoire de PEP après opération"
        },
        "unit": "%",
        "roundedTo":0,
        "error": "ewl_error",
    }
]



export const warningText = {
    "EN":<div>
    <p>Based on preoperative characteristics, this application displays predicted weights at 1, 3, 12, 24, and 60 months after a <b>first</b> bariatric surgery.</p>
    <ul>
        <li>Trajectories are displayed as smooth lines for clarity.</li>
        <li>The range around the curve is based on the interquartile range of prediction errors.</li>
        <li>Predictions are based on the histories of previous individuals undergoing bariatric surgery procedures.</li>
        <li>This program is not intended to provide any advice on healthcare decisions but rather to help healthcare professionals in visualizing the predicted weight trajectory following bariatric surgery.</li>
        <li>None of the personal or health data you enter on this tool is saved on our servers.</li>
        </ul></div>,
    "FR": <div>
    <p>Basé sur les informations pré-opératoires, cet outil présente les poids prédits aux mois 1, 3, 12, 24 et 60 après une <b>première</b> chirurgie bariatrique.</p>
    <ul>
        <li>Les trajectoires sont représentées par des courbes lissées pour une plus grande lisibilité.</li>
        <li>L'intervalle autour de la courbe est calculé à partir de l'écart interquartile des erreurs de prédiction.</li>
        <li>Les prédictions sont calculées à partir d'historiques de patients précédents ayant subi une opération bariatrique.</li>
        <li>Cet outil n'a pas pour but de fournir des conseils sur des décisions médicales mais plutôt d'aider les professionnels de santé à visualiser la trajectoire de poids prédite, après une chirurgie bariatrique.</li>
        <li>Aucune des données personnelles ou de santé que vous entrez sur cet outil n'est sauvegardée sur nos serveurs.</li>
        </ul></div>
}

export const aboutText = {
    "EN":<div>
    <h2 style={{color: COLORS.green_sophia}}>Note</h2>
    <p>Based on preoperative characteristics, this application displays predicted weights at 1, 3, 12, 24, and 60 months after a <b>first</b> bariatric surgery.</p>
    <ul>
      <li>Trajectories are displayed as smooth lines for clarity.</li>
      <li>The range around the curve is based on the interquartile range of prediction errors.</li>
      <li>Predictions are based on the histories of previous individuals undergoing bariatric surgery procedures.</li>
      <li>This program is not intended to provide any advice on healthcare decisions but rather to help healthcare professionals in visualizing the predicted weight trajectory following bariatric surgery.</li>
      <li>None of the personal or health data you enter on this tool is saved on our servers.</li>
    </ul>
    <h2 style={{color: COLORS.green_sophia}}>Definition of indicators</h2>
    <ul>
        {indicators_list.map(item => item.id !== "weight" && <li key={item.id}> {item.description.EN}</li>)}
    </ul>
    <h2 style={{color: COLORS.green_sophia}}>Publication</h2>
    <p>Saux, Patrick, et al. "Development and validation of an interpretable machine learning-based calculator for predicting 5-year weight trajectories after bariatric surgery: a multinational retrospective cohort SOPHIA study." The Lancet Digital Health (2023).</p>
    Article available here {"\xa0"} <FontAwesomeIcon icon={faHandPointRight} beat size='lg'/> {"\xa0"}
    <a  style={{ cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(23)00135-8/fulltext">
    <img
                alt=""
                src={require("../Images/lancet_digital_health.png")}
                id={"logoldh"}
                className="ldhlogo"
              />
    </a>
    </div>,
    "FR": <div>
    <h2 style={{color: COLORS.green_sophia}}>Informations</h2>
    <p>Basé sur les informations pré-opératoires, cet outil présente les poids prédits aux mois 1, 3, 12, 24 et 60 après une <b>première</b> chirurgie bariatrique.</p>
    <ul>
      <li>Les trajectoires sont représentées par des courbes lissées pour une plus grande lisibilité.</li>
      <li>L'intervalle autour de la courbe est calculé à partir de l'écart interquartile des erreurs de prédiction.</li>
      <li>Les prédictions sont calculées à partir d'historiques de patients précédents ayant subi une opération bariatrique.</li>
      <li>Cet outil n'a pas pour but de fournir des conseils sur des décisions médicales mais plutôt d'aider les professionnels de santé à visualiser la trajectoire de poids prédite, après une chirurgie bariatrique.</li>
      <li>Aucune des données personnelles ou de santé que vous entrez sur cet outil n'est sauvegardée sur nos serveurs.</li>
    </ul>
    <h2 style={{color: COLORS.green_sophia}}>Définition des indicateurs</h2>
    <ul>
        {indicators_list.map(item => item.id !== "weight" && <li key={item.id}> {item.description.FR}</li>)}
    </ul>
    <h2 style={{color: COLORS.green_sophia}}>Publication</h2>
    <p>Saux, Patrick, et al. "Development and validation of an interpretable machine learning-based calculator for predicting 5-year weight trajectories after bariatric surgery: a multinational retrospective cohort SOPHIA study." The Lancet Digital Health (2023).</p>
    Article disponmible ici {"\xa0"} <FontAwesomeIcon icon={faHandPointRight} beat size='lg'/> {"\xa0"}
    <a  style={{ cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(23)00135-8/fulltext">
    <img
                alt=""
                src={require("../Images/lancet_digital_health.png")}
                id={"logoldh"}
                className="ldhlogo"
              />
    </a>
</div>

}

export const main_logos = [
    {
        "name": "Inria",
        "url" : "https://www.inria.fr",
        "img" : "inr_logo_rouge.png"
    },
    {
        "name": "Centre Hospitalier Universitaire de Lille",
        "url" : "https://www.chu-lille.fr",
        "img" : "chu_logo.png"
    },

    {
        "name": "Inserm",
        "url" : "https://www.inserm.fr",
        "img" : "inserm_logo.png"
    },
    {
        "name": "CRIStAL",
        "url" : "https://www.cristal.univ-lille.fr",
        "img" : "logo_cristal.png"
    },
    {
        "name": "Université de Lille",
        "url" : "https://www.univ-lille.fr",
        "img" : "logo_univ_lille.png"
    },
    {
        "name": "CNRS",
        "url" : "https://www.cnrs.fr",
        "img" : "cnrs_logo.png"
    },
    {
        "name": "EGID",
        "url" : "https://egid.fr",
        "img" : "egid_logo.png"
    },

]

export const partners_logos = [
    {
        "name": "Centre Hospitalier Universitaire de Montpellier",
        "url" : "https://www.chu-montpellier.fr",
        "img" : "chu_montpellier.png"
    },
    {
        "name": "Nederlandse Obesitas Kliniek",
        "url" : "https://www.obesitaskliniek.nl",
        "img" : "ned_obesitas.png"
    },
    {
        "name": "Singapore General Hospital",
        "url" : "https://www.sgh.com.sg",
        "img" : "sgh.jpeg"
    },
    {
        "name": "University of Gothenburg",
        "url" : "https://www.gu.se/en/sahlgrenska-akademin",
        "img" : "sos_logo.png"
    },
    {
        "name": "St. Claraspital und Universitätsspital Basel",
        "url" : "https://www.clarunis.ch",
        "img" : "clarunis_logo.jpg"
    },
    {
        "name": "Centre Hospitalier d'Arras",
        "url" : "https://www.gh-artoisternois.fr",
        "img" : "ch_arras.png"
    },
    {
        "name": "Centre Hospitalier de Boulogne sur Mer",
        "url" : "https://www.ch-boulogne.fr",
        "img" : "ch_boulogne.png"
    },
    {
        "name": "Centre Hospitalier de Valenciennes",
        "url" : "https://www.ch-valenciennes.fr",
        "img" : "ch_valenciennes.png"
    },
    {
        "name": "Hospital Oswaldo Cruz, São Paulo",
        "url" : "https://centrodeobesidadeediabetes.org.br",
        "img" : "oswaldo_cruz_logo.png"
    },
];

export const sophia_partners_logos = [
    {
        "name": "IMI",
        "url" : "https://www.imi.europa.eu",
        "img" : "imi.png"
    }, {
        "name": "EFPIA",
        "url" : "https://www.efpia.eu",
        "img" : "efpia.png"
    },{
        "name": "Type 1 Diabetes Exchange",
        "url" : "https://t1dexchange.org",
        "img" : "t1de.png"
    },{
        "name": "JDRF",
        "url" : "https://www.jdrf.org",
        "img" : "jdrf.png"
    },{
        "name": "Obesity Action Coalition",
        "url" : "https://www.obesityaction.org",
        "img" : "oac.png"
    },{
        "name": "University College Dublin",
        "url" : "https://www.ucd.ie",
        "img" : "ucd.png"
    },{
        "name": "University of Dundee",
        "url" : "https://www.dundee.ac.uk",
        "img" : "udd.png"
    },{
        "name": "Lund University",
        "url" : "https://www.lunduniversity.lu.se/",
        "img" : "lund.png"
    },{
        "name": "University of Exeter ",
        "url" : "https://www.exeter.ac.uk/",
        "img" : "exe.png"
    },{
        "name": "Erasmus Medisch Centrum",
        "url" : "https://www.erasmusmc.nl/",
        "img" : "emc.png"
    },{
        "name": "Maastricht University",
        "url" : "https://www.maastrichtuniversity.nl/",
        "img" : "mu.png"
    },{
        "name": "University of Ulm",
        "url" : "https://www.uni-ulm.de/en/",
        "img" : "ulm.png"
    },{
        "name": "University of Gothenburg",
        "url" : "https://www.gu.se",
        "img" : "ugot.png"
    },{
        "name": "University College Cork",
        "url" : "https://www.ucc.ie/en/",
        "img" : "apc.png"
    },{
        "name": "George Washington University",
        "url" : "hhttps://www.gwu.edu",
        "img" : "gwu.png"
    },{
        "name": "Swiss Institute of Bioinformatics",
        "url" : "https://www.sib.swiss/",
        "img" : "sib.png"
    },{
        "name": "Katholieke Universiteit Leuven",
        "url" : "https://www.kuleuven.be/english/",
        "img" : "kul.png"
    },{
        "name": "Universita Cattolica del Sacro Cuore ",
        "url" : "https://www.unicatt.it/",
        "img" : "ucsc.png"
    },{
        "name": "Consiglio Nazionale delle Ricerche",
        "url" : "http://www.area.pi.cnr.it/",
        "img" : "cnr.png"
    },{
        "name": "Institut d’Investigacio Biomedica de Girona Dr. Josep Trueta",
        "url" : "https://www.idibgi.org/en/content/presentation",
        "img" : "idibgi.png"
    },{
        "name": "University Hospital Tubingen",
        "url" : "https://www.medizin.uni-tuebingen.de/en-de/das-klinikum",
        "img" : "uht.png"
    },{
        "name": "Centre Hospitalier Universitaire de Lille",
        "url" : "https://www.chu-lille.fr/",
        "img" : "chul.png"
    },{
        "name": "European Association for the Study of Obesity",
        "url" : "https://easo.org/",
        "img" : "easo.png"
    },{
        "name": "The University Hospital of Lausanne",
        "url" : "https://www.chuv.ch/fr/chuv-home/",
        "img" : "chuv.png"
    },{
        "name": "Novo Nordisk",
        "url" : "https://www.novonordisk.com",
        "img" : "novo.png"
    },{
        "name": "Medtronic International Trading SARL",
        "url" : "https://www.medtronic.com",
        "img" : "med.png"
    },{
        "name": "Boehringer Ingelheim Pharma GmbH & Co KG",
        "url" : "http://www.boehringer-ingelheim.com/",
        "img" : "bi.png"
    },{
        "name": "Eli Lilly and Company",
        "url" : "https://www.lilly.com",
        "img" : "lly.png"
    },{
        "name": "Pfizer Limited",
        "url" : "https://www.pfizer.com",
        "img" : "pfizer.png"
    },{
        "name": "Metabolon",
        "url" : "https://www.metabolon.com/",
        "img" : "met.png"
    },{
        "name": "Third-I",
        "url" : "https://third-i.eu/",
        "img" : "3i.png"
    },{
        "name": "Atturos",
        "url" : "http://atturos.com/",
        "img" : "att.png"
    },{
        "name": "Lipotype",
        "url" : "https://www.lipotype.com/",
        "img" : "lipo.png"
    },{
        "name": "Maccabi Health Services",
        "url" : "https://www.maccabi4u.co.il/1781-he/Maccabi.aspx",
        "img" : "maccabi.png"
    },
];

export const mail_address = "bariatric-weight-trajectory-prediction@univ-lille.fr"

export const contact_modal_labels = {
    "email_field_label": {
        "EN": "Your email address",
        "FR": "Votre adresse e-mail"
    },
    "email_field_placeholder": {
        "EN": "name@example.com",
        "FR": "nom@exemple.com"
    },
    "message_field_placeholder": {
        "EN": "Your message. You can add your email address to the message if you want a reply.",
        "FR": "Votre message. Vous pouvez ajouter votre adresse e-mail dans le message si vous souhaitez une réponse."
    },
    "send_button": {
        "EN": "Send",
        "FR": "Envoyer"
    },
    "send_email_indication": {
        "EN": "Or you can directly send us an email: ",
        "FR": "Ou vous pouvez nous contacter directement par e-mail :"
    }
}

export const home_labels = {
    "table_of_values_title": {
        "EN": "Table of values",
        "FR": "Table des valeurs"
    }
}

export const menu_bar_labels = {
    "prediction_tab": {
        "EN": "Prediction",
        "FR": "Prédiction"
    },
    "about_tab": {
        "EN": "About",
        "FR": "À propos"
    },
    "partners_tab": {
        "EN": "Partners",
        "FR": "Partenaires"
    },
    "contact_button": {
        "EN": "Contact",
        "FR": "Contact"
    }
}

export const partners_labels = {
    "partners": {
        "EN": "Partners",
        "FR": "Partenaires"
    },
    "sophia_partners": {
        "EN": "Partners & Funders",
        "FR": "Partenaires & Financeurs"
    },
    "sophia_project": {
        "EN": "This project has received funding from the Innovative Medicines Initiative 2 Joint Undertaking (JU) under grant agreement No 875534. The JU receives support from the European Union’s Horizon 2020 research and innovation programme and EFPIA and T1D Exchange, JDRF, and Obesity Action Coalition.",
        "FR": "This project has received funding from the Innovative Medicines Initiative 2 Joint Undertaking (JU) under grant agreement No 875534. The JU receives support from the European Union’s Horizon 2020 research and innovation programme and EFPIA and T1D Exchange, JDRF, and Obesity Action Coalition."
    },
}

export const prediction_counter_labels = {
    "predictions_number_indication": {
        "EN": "predictions have been made using that tool!",
        "FR": "prédictions ont été réalisées grâce à cet outil !"
    }
}

export const save_data_settings_labels = {
    "current_prediction_title": {
        "EN": "Current prediction",
        "FR": "Prédiction actuelle"
    },
    "stored_prediction_title": {
        "EN": "Stored prediction",
        "FR": "Prédiction archivée"
    },
    "yes": {
        "EN": "Yes",
        "FR": "Oui"
    },
    "no": {
        "EN": "No",
        "FR": "Non"
    },
    "store_prediction_tooltip": {
        "EN": "Store prediction",
        "FR": "Archiver la prédiction"
    },
    "display_stored_prediction": {
        "EN": "Display stored prediction",
        "FR": "Afficher la prédiction archivée"
    }

}

export const chart_labels = {
    "month": {
        "EN": "Month",
        "FR": "Mois"
    },
    "range": {
        "EN": "Range",
        "FR": "Intervalle"
    },
    "stored_prediction_title": {
        "EN": "Stored prediction",
        "FR": "Prédiction archivée"
    },
    "current_prediction_title": {
        "EN": "Current prediction",
        "FR": "Prédiction actuelle"
    },
    "x_axis_label": {
        "EN": "Months after surgery",
        "FR": "Mois après l'opération"
    }

}

export const patient_settings_labels = {
    "preoperative_data_category_title": {
        "EN": "Patient",
        "FR": "Patient"
    },
    "predict_button": {
        "EN": "Predict trajectory",
        "FR": "Prédire la trajectoire"
    },
    "surgery_data_category_title": {
        "EN": "Surgery",
        "FR": "Chirurgie"
    }

}

export const values_table_labels = {
    "month_prediction_title": {
        "EN": "Prediction for month",
        "FR": "Prédiction pour le mois"
    }
}

export const warning_modal_labels = {
    "modal_title": {
        "EN": "Note",
        "FR": "Informations"
    }
}
