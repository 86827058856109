import React, {useEffect} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { COLORS } from '../assets/colors';

import * as Config from '../assets/config'



function Partners(props) {


  useEffect(() => {
    props.setSelectedTab("/partners");
  })

  const displayMainLogos = () => {
    return(


        Config.main_logos.map((item, index) => {
          return(
            <Col key={index} className="justify-content-center align-items-center text-center p-3">


            <a  style={{ cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href={item.url}>
            <img
            alt=""
            src={require("../Images/" + item.img)}
            id={"logo"+ index}
            className="mainlogo"
          />
            </a>
            </Col>)})


    )
  }

  const displayPartnersLogos = () => {
    return(


        Config.partners_logos.map((item, index) => {
          return(
            <Col key={index} className="justify-content-center align-items-center text-center me-5  p-3">


            <a  style={{ cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href={item.url}>
            <img
            alt=""
            src={require("../Images/" + item.img)}
            id={"logo"+ index}
            className="partnerslogo"
          />
            </a>
            </Col>)})


    )
  }

  const displaySophiaPartnersLogos = () => {
    return(


        Config.sophia_partners_logos.map((item, index) => {
          return(
            <Col key={index} className="justify-content-center align-items-center text-center me-5 p-3">


            <a  style={{ cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href={item.url}>
            <img
            alt=""
            src={require("../Images/" + item.img)}
            id={"logo"+ index}
            className="sophiapartnerslogo"
          />
            </a>
            </Col>)})


    )
  }

  return (
    <div className='content'>
        <Row className='contentrow gx-0'>
            <Col style={styles.leftcol} xs={0} sm={4} className="justify-content-center leftcol horizontal-gradient">
            </Col>
            <Col className='rightcol' style={styles.ritghtcol} xs={12} sm={8}>
              <Row className='gx-0'>
                <Col className='pt-3 pt-sm-5 ps-3 ps-sm-0 pe-3 pe-sm-5'>
                  <div>
                    <Row className='gx-0 logos flex-row flex-nowrap  align-items-center'>
                      {displayMainLogos()}
                    </Row>
                    <h2 style={styles.title} className='mt-5'>{Config.partners_labels.partners[props.language]}</h2>
                    <Row className='gx-0 logos flex-row flex-nowrap  align-items-center '>
                      {displayPartnersLogos()}
                    </Row>

                    <hr className="mt-5" style={styles.hr}></hr>
                    <span><h2 style={styles.title} className='mt-5 mb-4'><span><a  style={{ cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href={"https://imisophia.eu"}>
                        <img
                          alt=""
                          src={require("../Images/sophia_logo.png")}
                          id={"sophialogo"}
                          className="sophialogopartners"
                        />
                      </a></span><span  className='ms-3'>{Config.partners_labels.sophia_partners[props.language]}</span></h2></span>
                    <Row className='gx-0 logos flex-row flex-nowrap  align-items-center'>
                      {displaySophiaPartnersLogos()}
                    </Row>
                    <Row  style={styles.sophiatext} className='gx-0 mt-5 text-center align-items-center'>
                      {Config.partners_labels.sophia_project[props.language]}
                    </Row>
                    <hr className="mt-5 mb-5" style={styles.hr}></hr>
                    <Row className='gx-0 europelogorow justify-content-center align-items-center'>
                      <Col xs={12} className="justify-content-center align-items-center text-center">
                      <a  style={{ cursor: 'pointer' }} target="_blank" rel="noopener noreferrer" href={"https://www.hautsdefrance.fr"}>
                        <img
                          alt=""
                          src={require("../Images/europe_hdf.png")}
                          id={"logoeurope"}
                          className=" logoeurope"
                        />
                      </a>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
        </Row>
    </div>
  );
}

const styles = {

  logoimg:{
    objectFit: 'cover',
  },

  leftcol: {
      backgroundColor : COLORS.light_green_sophia,
  },

  ritghtcol: {
      backgroundColor : COLORS.light_gray
  },
  hr: {
    color : COLORS.light_green_sophia,
    height: "5px"
  },
  sophiatext: {
    fontSize:"18px",
    color: COLORS.gray
  },

  title: {
    color: COLORS.green_sophia

  }
}

export default Partners;
