export const generic_smooth_func = (t, smooth_coef, intervention_type, tag) => {
    const coefs = smooth_coef[tag];
    let y = 0;
    if(intervention_type === 1){
        y = Math.max(
          coefs.a * Math.log(1 + Math.pow(t, 2)) - coefs.b / Math.pow(1 + t, 2) + coefs.c * t + coefs.intercept,
          0
        );
    }
    else {
        y = Math.max(
          coefs.D * Math.pow((coefs.a - Math.exp(-(t-coefs.t_min) / coefs.t_scale)), 2) - coefs.b / Math.pow(1 + t, 2) + coefs.intercept,
          0
        );
    }
    return y;
}


export const weight_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_line_weight");
}


export const weight_error_max_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_error_weight_max");
}


export const weight_error_min_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_error_weight_min");
}


export const twl_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_line_twl");
}


export const twl_error_max_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_error_twl_max");
}


export const twl_error_min_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_error_twl_min");
}


export const bmi_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_line_bmi");
}


export const bmi_error_max_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_error_bmi_max");
}


export const bmi_error_min_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_error_bmi_min");
}


export const ewl_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_line_ewl");
}


export const ewl_error_max_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_error_ewl_max");
}


export const ewl_error_min_func = (t, smooth_coef, intervention_type) => {
    return generic_smooth_func(t, smooth_coef, intervention_type, "coef_error_ewl_min");
}
