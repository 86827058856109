import React from 'react';

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { COLORS } from '../assets/colors';

import * as Config from '../assets/config'

function UniquePatientTable(props) {

    return (
        <Container className='containertable'>
            <Row className='align-items-center justify-content-center'>
                <Col  md="12" lg="12">
                    <Table   responsive>
                        <thead>
                            <tr >
                            <th></th>
                                {
                                    Config.indicators_list.map((item, index) => {return(<th style={styles.tableth} key={index} >{<div>{item.label_with_units[props.language][props.system]}&nbsp;
                                    {item.description[props.language] !== "" && <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                            {item.description[props.language]}
                                            </Tooltip>
                                        }
                                        >
                                        <i className="bi bi-question-circle-fill" style={{fontSize: "12px", verticalAlign: "top"}}></i>
                                        </OverlayTrigger>}</div>}
                                    </th> )})
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {props.tableData ? props.tableData.map(item =>
                            <tr key={item.month}>
                                <td style={styles.tabletd} className='tablefirstcol'>
                                    {Config.values_table_labels.month_prediction_title[props.language] + " " + item.month}
                                </td>
                                {Config.indicators_list.map(item2 =>
                                    {return(
                                        ( props.savedTableData && props.isSavedResultVisible ) ?
                                        <td style={styles.tabletd} key={item2.id} >
                                          <div className='rowDiv'> <div>{item[item2.id].toFixed(item2.roundedTo)} </div><div className='saveddatatable' style={styles.saveddata} >&nbsp;&nbsp;{props.savedTableData.find(e => e.month === item.month)[item2.id].toFixed(item2.roundedTo)}</div> </div>
                                        </td> :
                                        <td style={styles.tabletd} key={item2.id} >
                                        {item[item2.id].toFixed(item2.roundedTo)}
                                    </td>)
                                    })}
                            </tr>) : props.savedTableData.map(item =>
                            <tr key={item.month}>
                                <td style={styles.tabletd} className='tablefirstcol'>
                                    {Config.values_table_labels.month_prediction_title[props.language] + " " + item.month}
                                </td>
                                {Config.indicators_list.map(item2 =>
                                    {return(

                                        <td style={styles.tabletd} key={item2.id} >
                                           <div className='rowDiv'><div className='saveddatatable' style={styles.saveddata} >&nbsp;&nbsp;{item[item2.id].toFixed(item2.roundedTo)}</div> </div>
                                        </td> )
                                    })}
                            </tr>)}

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}


const styles = {


    tabletd : {
        color : COLORS.green_sophia,
        borderColor : COLORS.light_green_sophia,
        minWidth: 100,
        verticalAlign: "middle",
        textAlign:"center"


    },
    tableth: {
        color : COLORS.green_sophia,
        minWidth: 110,
        verticalAlign: "middle",
        textAlign:"center"

    },
    saveddata: {
        color: COLORS.dark_blue,
        fontSize: 14
    }
    }

export default UniquePatientTable;
