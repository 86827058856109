import React, {useEffect, useState} from 'react';

import Container from 'react-bootstrap/Container';
import {Label, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import TextTooltip  from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';

import { COLORS } from '../assets/colors';
import * as Config from '../assets/config'
import * as Smoothing from '../assets/smoothing'
function UniquePatientChart(props) {



    const [data, setData] = useState(null);

    const [savedData, setSavedData] = useState(null);

    useEffect(() => {

        const start = 0;
        const end = 60;
        const step = 1;
        const arrayLength = Math.floor(((end - start) / step)) + 1;
        const t_arr = [...Array(arrayLength).keys()].map(x => (x * step) + start);

        setData(null);
        if(props.smoothCoef){
            let y_val = t_arr.map(t => {
                return (
                    {
                        "t": t,
                        "weight": Smoothing.weight_func(t, props.smoothCoef, props.interventionType),
                        "weight_error": [Smoothing.weight_error_min_func(t, props.smoothCoef, props.interventionType), Smoothing.weight_error_max_func(t, props.smoothCoef, props.interventionType)],
                        "twl" : Smoothing.twl_func(t, props.smoothCoef, props.interventionType),
                        "twl_error": [Smoothing.twl_error_min_func(t, props.smoothCoef, props.interventionType), Smoothing.twl_error_max_func(t, props.smoothCoef, props.interventionType)],
                        "bmi" : Smoothing.bmi_func(t,props.smoothCoef, props.interventionType),
                        "bmi_error": [Smoothing.bmi_error_min_func(t, props.smoothCoef, props.interventionType), Smoothing.bmi_error_max_func(t, props.smoothCoef, props.interventionType)],
                        "ewl" : Smoothing.ewl_func(t, props.smoothCoef, props.interventionType),
                        "ewl_error": [Smoothing.ewl_error_min_func(t, props.smoothCoef, props.interventionType), Smoothing.ewl_error_max_func(t, props.smoothCoef, props.interventionType)]
                    }
                );
            });
            setData(y_val);
        }


    }, [props.smoothCoef]);



    useEffect(() => {

        const start = 0;
        const end = 60;
        const step = 1;
        const arrayLength = Math.floor(((end - start) / step)) + 1;
        const t_arr = [...Array(arrayLength).keys()].map(x => (x * step) + start);

        setSavedData(null);

        if(props.savedSmoothCoef) {
            let y_val_saved = t_arr.map(t => {

                return (
                    {
                        "t": t,
                        "saved_weight": Smoothing.weight_func(t, props.savedSmoothCoef, props.savedInterventionType),
                        "saved_weight_error": [Smoothing.weight_error_min_func(t, props.savedSmoothCoef, props.savedInterventionType), Smoothing.weight_error_max_func(t, props.savedSmoothCoef, props.savedInterventionType)],
                        "saved_twl" : Smoothing.twl_func(t, props.savedSmoothCoef, props.savedInterventionType),
                        "saved_twl_error": [Smoothing.twl_error_min_func(t, props.savedSmoothCoef, props.savedInterventionType), Smoothing.twl_error_max_func(t, props.savedSmoothCoef, props.savedInterventionType)],
                        "saved_bmi" : Smoothing.bmi_func(t,props.savedSmoothCoef, props.savedInterventionType),
                        "saved_bmi_error": [Smoothing.bmi_error_min_func(t, props.savedSmoothCoef, props.savedInterventionType), Smoothing.bmi_error_max_func(t, props.savedSmoothCoef, props.savedInterventionType)],
                        "saved_ewl" : Smoothing.ewl_func(t, props.savedSmoothCoef, props.savedInterventionType),
                        "saved_ewl_error": [Smoothing.ewl_error_min_func(t, props.savedSmoothCoef, props.savedInterventionType), Smoothing.ewl_error_max_func(t, props.savedSmoothCoef, props.savedInterventionType)]
                    }
                );
            });
            setSavedData(y_val_saved);

        }

    }, [props.savedSmoothCoef]);



      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length ) {
            if(props.savedSmoothCoef && props.isSavedResultVisible && props.smoothCoef && payload.length === 4){
                return (

                    <div className="custom-tooltip">
                    <center><b><p style={styles.chart_tooltip_month} >{`${Config.chart_labels.month[props.language]} ${label.toFixed(0)}`}</p></b>
                    <hr />
                    <b><p style={styles.chart_tooltip} >{Config.chart_labels.current_prediction_title[props.language]}</p></b>
                    <div style={styles.chart_tooltip} >{`${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).label[props.language]} : ${payload[3].value.toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div>
                    <div style={styles.chart_tooltip} >{`${Config.chart_labels.range[props.language]} : ${payload[2].value[0].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} - ${payload[2].value[1].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div></center>
                    <hr />
                    <center><b><p style={styles.chart_tooltip_stored} >{Config.chart_labels.stored_prediction_title[props.language]}</p></b>
                    <div style={styles.chart_tooltip_stored}>{`${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).label[props.language]} : ${payload[1].value.toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div>
                    <div style={styles.chart_tooltip_stored}>{`${Config.chart_labels.range[props.language]} : ${payload[0].value[0].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} - ${payload[0].value[1].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div></center>
                </div>
                )
            }
            else if(!props.savedSmoothCoef && props.smoothCoef && payload.length === 2){
                return(
                    <div className="custom-tooltip">
                        <center><b><p style={styles.chart_tooltip_month} >{`${Config.chart_labels.month[props.language]} ${label.toFixed(0)}`}</p></b>
                        <hr />
                        <div style={styles.chart_tooltip} >{`${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).label[props.language]} : ${payload[1].value.toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div>
                        <div style={styles.chart_tooltip} >{`${Config.chart_labels.range[props.language]} : ${payload[0].value[0].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} - ${payload[0].value[1].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div></center>
                    </div>
                )
            }
            else if(props.savedSmoothCoef && !props.isSavedResultVisible && props.smoothCoef && payload.length === 2){
                return(
                    <div className="custom-tooltip">
                        <center><b><p style={styles.chart_tooltip_month} >{`${Config.chart_labels.month[props.language]} ${label.toFixed(0)}`}</p></b>
                        <hr />
                        <b><p style={styles.chart_tooltip} >{Config.chart_labels.current_prediction_title[props.language]}</p></b>
                        <div style={styles.chart_tooltip} >{`${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).label[props.language]} : ${payload[1].value.toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div>
                        <div style={styles.chart_tooltip} >{`${Config.chart_labels.range[props.language]} : ${payload[0].value[0].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} - ${payload[0].value[1].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div></center>
                    </div>
                )
            }
            else if(props.savedSmoothCoef && props.isSavedResultVisible && !props.smoothCoef && payload.length === 2){
                return(
                    <div className="custom-tooltip">
                        <center><b><p style={styles.chart_tooltip_month} >{`${Config.chart_labels.month[props.language]} ${label.toFixed(0)}`}</p></b>
                        <hr />
                        <b><p style={styles.chart_tooltip_stored} >{Config.chart_labels.stored_prediction_title[props.language]}</p></b>
                        <div style={styles.chart_tooltip_stored}>{`${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).label[props.language]} : ${payload[1].value.toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div>
                        <div style={styles.chart_tooltip_stored}>{`${Config.chart_labels.range[props.language]} : ${payload[0].value[0].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} - ${payload[0].value[1].toFixed(Config.indicators_list.find(e => e.id === props.indicatorToDisplay).roundedTo)} ${Config.indicators_list.find(e => e.id === props.indicatorToDisplay).unit}`}</div></center>
                    </div>
                )
            }


        }

        return null;
      };




    return (
        <Container className='containerchart'>
            <Row className='mt-3'>
                <Col className='indicatorcol'>
                    <Form.Select
                        onChange={e => props.setIndicatorToDisplay(e.target.value)}
                        value={props.indicatorToDisplay}>
                            {Config.indicators_list.map((item, index) => {return(<option key={index} value={item.id}>{item.label[props.language]}</option>)})}

                    </Form.Select>
                </Col>
                <Col className="justify-content-end text-end">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <TextTooltip >
                                {Config.indicators_list.find(e => e.id === props.indicatorToDisplay).chart_description[props.language]}
                            </TextTooltip>
                        }
                        >
                        <i className="bi bi-question-circle-fill" style={{color: COLORS.green_sophia, fontSize: "18px"}}></i>
                    </OverlayTrigger>
                </Col>
            </Row>
            <ResponsiveContainer width="100%">
                <ComposedChart
                margin={{
                    top: 30,
                    right: 30,
                    left: 30,
                    bottom: 30,
                }}
                >

                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="t"
                    type="number"
                    allowDuplicatedCategory={false}

                    ticks={[1, 3, 12, 24, 60]}
                    >
                        <Label value={Config.chart_labels.x_axis_label[props.language]} offset={-10} position="insideBottom" />
                    </XAxis>

                    <YAxis
                    domain={['auto','auto']}
                    >

                        <Label value={Config.indicators_list.find(e => e.id === props.indicatorToDisplay).label_with_units[props.language][props.system]} angle={-90} offset={0} position="insideLeft" />
                     </YAxis>
                    <Tooltip

                    content={<CustomTooltip/>}
                    />

                    {savedData &&  <Area type="linear" hide={!props.isSavedResultVisible} dot={false} data={savedData} dataKey={"saved_" + Config.indicators_list.find(e => e.id === props.indicatorToDisplay).error} stroke={COLORS.light_blue} fill={COLORS.light_blue} isAnimationActive={false} animationDuration={2000}/>}
                    {savedData && <Line type="linear" hide={!props.isSavedResultVisible} dot={false} strokeWidth={3} strokeDasharray="3 3" data={savedData} dataKey={"saved_" + props.indicatorToDisplay} stroke={COLORS.dark_blue}  isAnimationActive={false} animationDuration={2000}/>}

                    {data && <Area type="linear" dot={false} data={data} dataKey={Config.indicators_list.find(e => e.id === props.indicatorToDisplay).error} stroke={COLORS.area_green_sophia} fill={COLORS.area_green_sophia} isAnimationActive={true} animationDuration={3000}/>}
                    {data &&  <Line  type="linear" dot={false} strokeWidth={3} strokeDasharray="3 3" data={data} dataKey={props.indicatorToDisplay} stroke={COLORS.green_sophia}  isAnimationActive={true} animationDuration={3000} />}





                </ComposedChart>
            </ResponsiveContainer>
            <Row>
                <Col style={styles.chart_title} className='text-center'>
                    {Config.indicators_list.find(e => e.id === props.indicatorToDisplay).chart_title[props.language]}
                </Col>
            </Row>
        </Container>
    );
}


const styles = {
    chart_title : {
        fontSize: 25,
        color : COLORS.green_sophia,
    },

    chart_tooltip : {
        color : COLORS.green_sophia
    },
    chart_tooltip_month : {
        color : COLORS.gray
    },

    chart_tooltip_stored : {
        color : COLORS.dark_blue
    }




  }

export default UniquePatientChart;
